<template>
    <div class="benefits innerContainer" @mouseenter="store.getComponentCursor($event, null)">
        <div class="content">
            <h2>{{ item.title }}</h2>
            <span v-html="item.content">
        </span>
        </div>
        <div class="image-container"
             data-scroll
             data-scroll-speed="1">
            <picture class="picture">
                <source
                    media="(max-width:767px)"
                    :srcset="item.mobile1"
                />
                <source
                    media="(max-width:1023px)"
                    :srcset="item.tablet1"
                />
                <img class="down-image"
                     :src="item.desktop1"
                />
            </picture>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import Swiper from "swiper";
import {useMenuStore} from "@/store/pinia/menu";

const store = useMenuStore();

const props = defineProps({
    contentData: {
        type: Object,
    },
});

let item = computed(() => {
    let item = props.contentData.data?.list?.[0]
    return {
        ...item,
        mobile1: item?.images?.[0]?.devices?.mobile,
        desktop1: item?.images?.[0]?.devices?.desktop,
        tablet1: item?.images?.[0]?.devices?.tablet,
    }
})

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.23, -0.01, .03, 1);
.benefits {
    margin-top: 89px;
    display: flex;
    align-items: center;
    color: $primaryNavy;
    @media (max-width: 767px) {
        margin-top: 55px;
        flex-direction: column;
    }

    .content {
        width: calc(50% - 10px);

        h1, h2, h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 55px;
            text-transform: uppercase;
            margin-top: 55px;
            transition: all .45s ease-out;
            font-family: 'Luxury', 'BesarionCaps';

            &:first-child {
                margin-top: 0;
            }

            @media (max-width: 1440px) {
                font-size: 34px;
            }
        }

        p, span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            font-family: FiraGO;
            margin-top: 21px;
            transition: all .45s ease-out;

        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .image-container {
        width: calc(50% - 10px);
        overflow: hidden;
        margin-left: 21px;
        will-change: transform;

        @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
        }
    }

    .picture {
        width: 100%;
    }

    img {
        width: 100%;
        max-height: 610px;
        object-fit: cover;
        transition: all .45s $ease;

        &:hover {
            transform: scale(1.1);
        }

        @media (max-width: 767px) {
            margin-top: 34px;
            margin-left: 0;
            max-height: 300px;
        }
        @media only screen and (max-width: 1650px) and (min-width: 768px) {
            max-height: 450px;
        }
    }
}
</style>

<style lang="scss">
body {
    &.is-dark {
        .benefits {
            color: white;
        }
    }
}

html[lang="ka"] {
    .benefits {
        .content {
            h1, h2, h3 {
                font-family: BesarionCaps;
            }
        }
    }
}

html[lang="en"] {
    .benefits {
        .content {
            h1, h2, h3 {
                font-family: Luxury;
            }
        }
    }
}
</style>
